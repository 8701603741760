import { Menu } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuArray } from "./const/constants";
import { convertStringToArray } from "../utils/format";

const MenuAntd = () => {
  const converDataMenu = MenuArray.map((item) => ({
    ...item,
    enabled: true,
    children: item?.children?.map((o) => ({
      ...o,
      enabled: true,
    })),
  }));

  const navigate = useNavigate();
  const location = useLocation();
  const foundMenu = converDataMenu?.find((o) =>
    o?.children?.find(
      (e) => e.key === `/${convertStringToArray(location.pathname, /[/]+/)[1]}`
    )
  );
  const rootSubmenuKeys = converDataMenu.map((o) => o?.key);

  const [openKeys, setOpenKeys] = useState([`${foundMenu?.key || ""}`]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onClick = (e) => {
    if (e.key !== location.pathname) {
      navigate(e.key);
    }
  };

  return (
    <Menu
      mode="inline"
      onClick={onClick}
      openKeys={openKeys}
      selectedKeys={[
        `/${convertStringToArray(location.pathname, /[/]+/)[1] || ""}`,
      ]}
      onOpenChange={onOpenChange}
      style={{ width: 262 }}
      items={MenuArray}
    />
  );
};

export default MenuAntd;
