import React from "react";

import { AppProvider } from "./providers";
// import AppRoutes from "./routes";
import "./styles/other/style.css";
import AppRoutes from "./routes";
// import "antd/dist/antd.css";
// import { ProfileContext } from "./utils/hooks/ProfileContext";
import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";

Amplify.configure(awsExports);

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
