const storagePrefix = "W2G_";

const storage = {
  getToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}accessToken`)
    );
  },
  setToken: (token) => {
    window.localStorage.setItem(
      `${storagePrefix}accessToken`,
      JSON.stringify(token)
    );
  },
  getRefreshToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}refreshToken`)
    );
  },
  setRefreshToken: (token) => {
    window.localStorage.setItem(
      `${storagePrefix}refreshToken`,
      JSON.stringify(token)
    );
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}refreshToken`);
    window.localStorage.removeItem(`${storagePrefix}accessToken`);
  },
  getProfile: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}Profile`));
  },
  setProfile: (token) => {
    window.localStorage.setItem(
      `${storagePrefix}Profile`,
      JSON.stringify(token)
    );
  },
};

export default storage;
