export const gui = {
  dateFormat: "DD/MM/YYYY",
  mainColor: "#45AF0F",
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
  screenWidthOuter: window.outerWidth,
  screenHeightOuter: window.outerHeight,
  keyLoading: "pages.ac",
  pageSize: 20,
  dateFormat2: "DD/MM/YYYY HH:mm",
  dateFormatValue: "YYYY/MM/DD",
  dateTimeFormatOfApi: "YYYY-MM-DDTHH:mm:ss",
  timeFormat: "HH:mm",
  dataFormatValue: "YYYY-MM-DD",
  acceptFile: {
    "application/vnd.ms-excel": [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    "text/plain": [],
    "application/msword": [],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      [],
    "application/pdf": [],
    "image/jpeg": [],
    "image/jpg": [],
    "image/png": [],
  },
};
