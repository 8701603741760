/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Modal } from "antd";
import React from "react";

import { BtnLoading } from "../button/BtnLoading";

export const CtPopupConfirm = ({
  isOpen,
  onClose,
  title,
  children,
  onOk,
  txtOk,
  type,
  textContent,
  onlyClose,
  loading = false,
  widthOk,
  width,
  icon,
}) => {
  return (
    <>
      <Modal
        title={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>{icon}</div>
            {title?.toUpperCase() || ""}
          </div>
        }
        // centered
        open={isOpen}
        onCancel={() => onClose()}
        // maskClosable={false}
        closable={true}
        width={width}
        footer={
          <div style={{ justifyContent: "center" }} className="ct-flex-row">
            {!onlyClose && (
              <div onClick={() => onClose()} className="btn-close ct-flex-row">
                Hủy
              </div>
            )}
            <BtnLoading
              loading={loading}
              onClick={onOk}
              label={txtOk || "Xóa"}
              disabled={loading}
              style={{
                backgroundColor: type === "delete" ? "#FF3333" : "#45AF0F",
                border: type === "delete" ? "#FF3333" : "#45AF0F",
                width: type === "delete" ? "96px" : "",
              }}
              widthOk={widthOk}
            />
          </div>
        }
      >
        {children || <div>{textContent || title}</div>}
      </Modal>
    </>
  );
};
