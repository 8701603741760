const IconArrowLeft = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6564 7.32115H4.04305L10.3955 1.89258C10.4971 1.80508 10.4354 1.64258 10.3012 1.64258H8.69582C8.62508 1.64258 8.55796 1.66758 8.50536 1.71222L1.65045 7.56758C1.58767 7.62115 1.53732 7.68739 1.50281 7.76179C1.4683 7.8362 1.45044 7.91704 1.45044 7.99883C1.45044 8.08062 1.4683 8.16146 1.50281 8.23587C1.53732 8.31027 1.58767 8.3765 1.65045 8.43008L8.54526 14.3211C8.57247 14.3444 8.60512 14.3569 8.63959 14.3569H10.2993C10.4336 14.3569 10.4953 14.1926 10.3937 14.1069L4.04305 8.67829H14.6564C14.7363 8.67829 14.8016 8.61401 14.8016 8.53544V7.46401C14.8016 7.38544 14.7363 7.32115 14.6564 7.32115Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);

const IconCopy = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 8H10C8.89543 8 8 8.89543 8 10V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V10C21 8.89543 20.1046 8 19 8Z"
      stroke="black"
      strokeOpacity="0.36"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 14H3C2.46957 14 1.96086 13.7893 1.58579 13.4142C1.21071 13.0391 1 12.5304 1 12V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V4"
      stroke="black"
      strokeOpacity="0.36"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IconClear = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_701_54541)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2413 4.38274C14.4288 4.1952 14.6832 4.08984 14.9484 4.08984C15.2136 4.08984 15.468 4.1952 15.6555 4.38274L19.8981 8.62538C19.991 8.71823 20.0647 8.82847 20.1149 8.9498C20.1652 9.07113 20.191 9.20116 20.191 9.33249C20.191 9.46381 20.1652 9.59384 20.1149 9.71517C20.0647 9.8365 19.991 9.94674 19.8981 10.0396L14.9482 14.9896L9.2913 9.33273L14.2413 4.38274ZM8.21837 8.99144L2.92758 14.2822C2.74187 14.4679 2.59455 14.6884 2.49404 14.9311C2.39353 15.1737 2.3418 15.4338 2.3418 15.6964C2.3418 15.9591 2.39353 16.2192 2.49404 16.4618C2.59455 16.7045 2.74187 16.9249 2.92758 17.1107L6.40462 20.5877C6.49839 20.6815 6.62556 20.7341 6.75817 20.7341H20.2517C20.5278 20.7341 20.7517 20.5103 20.7517 20.2341C20.7517 19.958 20.5278 19.7341 20.2517 19.7341H11.6178L20.6053 10.7467C20.791 10.561 20.9383 10.3405 21.0388 10.0979C21.1393 9.8552 21.191 9.59513 21.191 9.33249C21.191 9.06984 21.1393 8.80977 21.0388 8.56712C20.9383 8.32447 20.791 8.10399 20.6053 7.91827L16.3626 3.67563C15.9875 3.30056 15.4788 3.08984 14.9484 3.08984C14.418 3.08984 13.9093 3.30056 13.5342 3.67563L8.24291 8.96691C8.2387 8.97084 8.23454 8.97486 8.23043 8.97897C8.22632 8.98307 8.2223 8.98723 8.21837 8.99144ZM8.58419 10.0398L14.241 15.6967L10.2036 19.7341H6.96528L3.63469 16.4036C3.54183 16.3107 3.46817 16.2005 3.41792 16.0791C3.36766 15.9578 3.3418 15.8278 3.3418 15.6964C3.3418 15.5651 3.36766 15.4351 3.41792 15.3138C3.46817 15.1924 3.54183 15.0822 3.63469 14.9893L8.58419 10.0398Z"
        fill="black"
        fillOpacity="0.48"
      />
    </g>
    <defs>
      <clipPath id="clip0_701_54541">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IconClearX = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill="white"
      stroke="black"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9L9 15"
      stroke="black"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9L15 15"
      stroke="black"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IconUploadImages = () => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.86775 10.4205C9.12932 10.5288 9.29987 10.7841 9.29987 11.0672V16.6672C9.29987 17.0538 8.98647 17.3672 8.59987 17.3672C8.21327 17.3672 7.89987 17.0538 7.89987 16.6672V12.7572L7.22818 13.4288C6.95481 13.7022 6.5116 13.7022 6.23823 13.4288C5.96486 13.1555 5.96486 12.7123 6.23823 12.4389L8.10489 10.5722C8.30509 10.372 8.60618 10.3121 8.86775 10.4205Z"
      fill="#2188FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.10493 10.5722C8.37829 10.2988 8.82151 10.2988 9.09488 10.5722L10.9615 12.4389C11.2349 12.7122 11.2349 13.1555 10.9615 13.4288C10.6882 13.7022 10.245 13.7022 9.97159 13.4288L8.10493 11.5622C7.83156 11.2888 7.83156 10.8456 8.10493 10.5722Z"
      fill="#2188FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.07822 4.67649C3.22372 5.53098 2.76658 6.93389 2.76658 9.19818V14.7982C2.76658 17.0625 3.22372 18.4654 4.07822 19.3199C4.93271 20.1744 6.33562 20.6315 8.59991 20.6315H14.1999C16.4642 20.6315 17.8671 20.1744 18.7216 19.3199C19.5761 18.4654 20.0332 17.0625 20.0332 14.7982V10.1315C20.0332 9.74491 20.3466 9.43151 20.7332 9.43151C21.1198 9.43151 21.4332 9.74491 21.4332 10.1315V14.7982C21.4332 17.2006 20.9571 19.0643 19.7116 20.3098C18.4661 21.5553 16.6023 22.0315 14.1999 22.0315H8.59991C6.19754 22.0315 4.33377 21.5553 3.08827 20.3098C1.84277 19.0643 1.36658 17.2006 1.36658 14.7982V9.19818C1.36658 6.7958 1.84277 4.93204 3.08827 3.68654C4.33377 2.44103 6.19754 1.96484 8.59991 1.96484H13.2666C13.6532 1.96484 13.9666 2.27824 13.9666 2.66484C13.9666 3.05144 13.6532 3.36484 13.2666 3.36484H8.59991C6.33562 3.36484 4.93271 3.82199 4.07822 4.67649Z"
      fill="#2188FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9986 2.01815C13.2602 1.9098 13.5613 1.96969 13.7615 2.16989L21.2282 9.63656C21.4284 9.83676 21.4883 10.1378 21.3799 10.3994C21.2716 10.661 21.0163 10.8315 20.7332 10.8315H16.9999C15.5611 10.8315 14.3791 10.6008 13.5882 9.80984C12.7973 9.01891 12.5665 7.83698 12.5665 6.3982V2.66486C12.5665 2.38174 12.7371 2.1265 12.9986 2.01815ZM13.9665 4.35481V6.3982C13.9665 7.75942 14.2024 8.44415 14.5782 8.81989C14.9539 9.19563 15.6386 9.43153 16.9999 9.43153H19.0432L13.9665 4.35481Z"
      fill="#2188FF"
    />
  </svg>
);

const IconParkingZone = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M30.1968 25.1554C33.7419 22.0146 33.3811 18.051 29.3883 16.3049C25.3955 14.5589 19.2853 15.6908 15.7391 18.8325C14.3127 20.0963 13.52 21.4922 13.34 22.8241H13.3373L12.5797 31.1993L24.9801 27.9161H24.9772C26.8994 27.392 28.6818 26.4493 30.1968 25.1554Z"
      fill="#BCB6B1"
    />
    <path
      d="M24.1942 12.2459C24.1898 10.4005 23.7482 8.58232 22.9056 6.9405C22.0629 5.29868 20.8432 3.87994 19.3465 2.80046C17.8497 1.72098 16.1185 1.01149 14.2946 0.730214C12.4708 0.448938 10.6062 0.603854 8.8538 1.18224C7.10135 1.76062 5.51081 2.74602 4.21269 4.05769C2.91457 5.36936 1.94577 6.96997 1.3856 8.72832C0.825441 10.4867 0.689869 12.3527 0.990063 14.1736C1.29026 15.9944 2.01766 17.7183 3.11262 19.2038H3.10761L12.514 31.2217L21.9204 19.2038H21.9157C23.3996 17.1877 24.1982 14.7491 24.1942 12.2459Z"
      fill="#5773EE"
    />
    <path
      d="M12.5144 20.6808C17.2631 20.6808 21.1126 16.8312 21.1126 12.0826C21.1126 7.33392 17.2631 3.48438 12.5144 3.48438C7.76575 3.48438 3.9162 7.33392 3.9162 12.0826C3.9162 16.8312 7.76575 20.6808 12.5144 20.6808Z"
      fill="white"
    />
    <path
      d="M11.9342 14.1234V17.5001H9.53531V7.54736H13.507C14.0551 7.53957 14.6005 7.62506 15.1199 7.80018C15.5685 7.95134 15.9824 8.19068 16.3371 8.50418C16.6667 8.80097 16.9278 9.16598 17.102 9.57388C17.2829 10.0007 17.3739 10.4603 17.369 10.9238C17.3756 11.3753 17.2844 11.823 17.102 12.2361C16.9264 12.6256 16.6649 12.9702 16.3371 13.2442C15.978 13.5387 15.5644 13.7595 15.1199 13.8938C14.5973 14.0529 14.0532 14.1302 13.507 14.1227L11.9342 14.1234ZM11.9342 12.2709H13.5064C13.7259 12.2759 13.9446 12.2423 14.1526 12.1717C14.3195 12.1138 14.4714 12.0193 14.597 11.895C14.7138 11.7752 14.8013 11.6302 14.8532 11.4711C14.9091 11.2989 14.9369 11.1188 14.9353 10.9378C14.9363 10.7401 14.9086 10.5433 14.8532 10.3535C14.8015 10.175 14.7144 10.0087 14.597 9.86465C14.476 9.72007 14.3241 9.60449 14.1526 9.52636C13.949 9.43745 13.7284 9.39435 13.5064 9.4001H11.9342V12.2709Z"
      fill="#21326F"
    />
  </svg>
);

export {
  IconArrowLeft,
  IconCopy,
  IconClear,
  IconClearX,
  IconUploadImages,
  IconParkingZone,
};
