/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
import { Button, ConfigProvider, Table, Tooltip } from "antd";
import React, { useState, useEffect } from "react";

import useUrlState from "@ahooksjs/use-url-state";
import { CtPagination } from "./CtPagination";
import { HeaderTable } from "../header/HeaderTable";
import {
  IconDelete,
  IconEdit,
  IconSave,
  IconSetting,
  IconSettingEnabled,
  IconViewDetail,
} from "../icons/headerIcon";
import dayjs from "dayjs";
import ColumnList from "./components/ColumnList";
import { Link } from "react-router-dom";
import {
  IconCheckBox,
  IconSyncTable,
  IconUnCheckBox,
} from "../icons/tableIcon";
import { gui } from "../../../utils/gui";

export const MainTable = ({
  columns,
  data = [],
  scrollX,
  limit = 0,
  page = 0,
  total = 0,
  loading,
  scrollY,
  onClickDeleteItem,
  onClickViewItem,
  onClickUpdateItem,
  onClickSetting,
  onRow = undefined,
  listBtn,
  showSelect = true,
  showHeader = true,
  callback,
  selectItem,
  ctRow,
  styles,
  title,
  draggable,
  placeholderSearch,
  searchColumn,
  linkToDetail = { link: "#", field: "id" },
  showSearch = true,
  showStt = true,
  onClickSave,
  showSearchSub,
  onClickSync,
  tableType,
}) => {
  const [params, setParams] = useUrlState({
    OrderBy: "",
    OrderDirection: "",
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState(selectItem || []);

  useEffect(() => {
    callback && callback(selectedRowKeys);
  }, [selectedRowKeys]);

  useEffect(() => {
    if (selectItem?.length == 0 && selectedRowKeys?.length > 0) {
      setSelectedRowKeys([]);
    }
  }, [selectItem]);

  const customizeRenderEmpty = () => <div>Không có dữ liệu</div>;

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const itemThaoTac = {
    title: null,
    dataIndex: "actions",
    width: 130,
    align: "center",
    fixed: "right",
    render: (value, item) => (
      <div
        className="ct-flex-row"
        style={{ fontSize: 14, justifyContent: "center" }}
      >
        {onClickViewItem && (
          <Tooltip placement="top" title={"Xem chi tiết"}>
            <Button
              icon={<IconViewDetail />}
              type="text"
              onClick={() => onClickViewItem(item)}
            />
          </Tooltip>
        )}
        {onClickUpdateItem && (
          <Tooltip placement="top" title={"Chỉnh sửa"}>
            <Button
              type="text"
              onClick={() => onClickUpdateItem(item)}
              style={{
                opacity: item.hiddenEdit ? 0.2 : 1,
              }}
              disabled={item.hiddenEdit}
              icon={<IconEdit />}
            />
          </Tooltip>
        )}
        {onClickDeleteItem
          ? (!item?.perDelete || item?.perDelete === "true") && (
              <Tooltip placement="top" title={"Xóa"}>
                <Button
                  type="text"
                  onClick={() => onClickDeleteItem(item)}
                  icon={<IconDelete />}
                />
              </Tooltip>
            )
          : null}
        {onClickSetting ? (
          <Tooltip placement="top" title={"Cấu hình"}>
            <Button
              type="text"
              disabled={!item.id || !item.code}
              style={{
                opacity: !item.id || !item.code ? 0.2 : 1,
              }}
              onClick={() => onClickSetting(item)}
              icon={
                params?.utilCode &&
                item.code &&
                item?.code === params?.utilCode ? (
                  <IconSettingEnabled />
                ) : (
                  <IconSetting />
                )
              }
            />
          </Tooltip>
        ) : null}
        {onClickSave ? (
          <Tooltip placement="top" title={"Lưu"}>
            <Button
              type="text"
              style={{
                opacity: item.disabledClickSave ? 0.3 : 1,
              }}
              disabled={item.disabledClickSave}
              onClick={() => onClickSave(item)}
              icon={<IconSave />}
            />
          </Tooltip>
        ) : null}
        {onClickSync && item.isSync ? (
          <Tooltip placement="top" title={"Cập nhật lại"}>
            <Button
              type="text"
              onClick={() => onClickSync(item)}
              icon={<IconSyncTable />}
            />
          </Tooltip>
        ) : null}
      </div>
    ),
  };

  const onChange = (pagination, filters, sorter) => {
    const dto = {
      PageId: 1,
      OrderBy: sorter.field,
      OrderDirection:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };
    tableType === "state" ? callback([], dto) : setParams(dto);
  };

  const ctRender = (v, item, o) => {
    const stl = { cursor: draggable ? "move" : "", fontSize: 14 };
    switch (o?.type) {
      case "object":
        if (item[o.objectField[0]]) {
          if (o.objectFilter) {
            const find = o?.objectFilter?.find(
              (w) => w.value === item[o.objectField[0]][o.objectField[1]]
            );
            return (
              <div style={{ color: find?.color || "", ...stl }}>
                {find?.label}
              </div>
            );
          }
          return (
            <div style={stl}>{item[o.objectField[0]][o.objectField[1]]}</div>
          );
        }
        break;
      case "image":
        return (
          <div style={stl}>
            {v && (
              <img
                style={{
                  width: 68,
                  height: 68,
                  borderRadius: 4,
                  overflow: "hidden",
                }}
                // src={`${v}?token=${token}`}
                alt="Error"
              />
            )}
          </div>
        );
      case "boolean":
        return (
          <div style={stl}>{v ? <IconCheckBox /> : <IconUnCheckBox />}</div>
        );
      case "link":
        if (item.hiddenEdit) {
          return <div>{v}</div>;
        }
        return (
          <Link
            style={{ color: "#1890FF" }}
            to={`${linkToDetail.link}/${item[linkToDetail.field || "id"]}`}
          >
            {v}
          </Link>
        );
      case "list":
        return (
          <div style={stl}>
            <ColumnList
              data={v?.filter((c) => c.name || c[o.fieldName])}
              link={o.link}
              fieldName={o.fieldName}
            />
          </div>
        );
      case "date":
        return (
          <div style={stl}>{v ? dayjs(v).format("DD/MM/YYYY") : null}</div>
        );
      case "time":
        return <div style={stl}>{v ? dayjs(v).format("HH:mm:ss") : null}</div>;
      case "datetime":
        return (
          <div style={stl}>
            {v ? dayjs(v).format("DD/MM/YYYY HH:mm:ss") : null}
          </div>
        );
      case "filter":
        const findItem = o?.filterData?.find((o) => o.value === v);
        return (
          <div style={{ color: findItem?.color || "", ...stl }}>
            {findItem?.label}
          </div>
        );
      default:
        return <div style={{ ...stl }}>{v}</div>;
    }
  };

  const itemStt = {
    width: 65,
    render: (v, item, index) => {
      if (page && limit) {
        return (
          <div style={{ cursor: draggable ? "move" : "" }}>
            {index + 1 + (parseInt(page) - 1) * parseInt(limit)}
          </div>
        );
      }
      return <div>{index + 1}</div>;
    },
    align: "center",
  };

  const newColumn = [
    showStt
      ? searchColumn
        ? {
            title: "STT",
            children: [
              {
                ...itemStt,
              },
            ],
          }
        : {
            title: "STT",
            ...itemStt,
          }
      : null,
    ...columns,
    onClickDeleteItem ||
    onClickViewItem ||
    onClickUpdateItem ||
    onClickSetting ||
    onClickSync
      ? searchColumn
        ? {
            title: "Hành động",
            align: "center",
            children: [
              {
                ...itemThaoTac,
              },
            ],
          }
        : {
            ...itemThaoTac,
            title: "Hành động",
            align: "center",
            sorter: null,
          }
      : null,
  ]
    .filter((o) => o?.title || o?.dataIndex || o?.width)
    .map((o) => ({
      ...o,
      sortOrder:
        o?.dataIndex === params.OrderBy
          ? params.OrderDirection === "DESC"
            ? "descend"
            : params.OrderDirection === "ASC"
            ? "ascend"
            : null
          : null,
      children: o.children?.map((i) => ({
        ...i,
        render: i.render
          ? i.render
          : i.type
          ? (v, item) => ctRender(v, item, i)
          : undefined,
      })),
      render: o?.render
        ? o?.render
        : o.type
        ? (v, item) => ctRender(v, item, o)
        : undefined,
    }));

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <div
        style={{
          minWidth: limit && 1000,
          ...styles,
        }}
      >
        {(listBtn || title) && (
          <HeaderTable
            placeholderSearch={placeholderSearch}
            showSearch={showSearch}
            listBtn={listBtn}
            title={title}
            showSearchSub={showSearchSub}
          />
        )}
        <Table
          loading={loading || false}
          components={{
            body: {
              row: ctRow ? ctRow : undefined,
            },
          }}
          showHeader={showHeader}
          columns={newColumn}
          onRow={onRow}
          rowSelection={showSelect ? rowSelection : undefined}
          scroll={{ x: scrollX, y: scrollY || gui.screenHeight - 250 }}
          dataSource={
            (data?.length && data?.map((o, index) => ({ ...o, key: o.id }))) ||
            []
          }
          pagination={false}
          size="small"
          rowKey="key"
          showSorterTooltip={false}
          onChange={onChange}
          rowClassName={(record, index) =>
            `${draggable ? "cursor-move" : ""} ${
              index % 2 === 0 ? "rowChan" : "rowLe"
            }`
          }
        />
      </div>
      {(total && limit && page && (
        <CtPagination
          total={total}
          limit={limit}
          PageId={page}
          tableType={tableType}
          callback={callback}
        />
      )) ||
        null}
    </ConfigProvider>
  );
};
