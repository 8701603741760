/* eslint-disable */

import React, { useEffect, useState } from "react";
import { ContentLayout } from "../../components/Layout";
import { MainTable } from "../../components/custom/table/MainTable";
import useUrlState from "@ahooksjs/use-url-state";
import { gui } from "../../utils/gui";
import { COLUMN_TABLE } from "./constants";
import { callApiGet } from "../../lib/axios";
import { useNavigate } from "react-router";

export const Notif = () => {
  const navigate = useNavigate();
  const [params, setParams] = useUrlState({
    PageSize: gui.pageSize,
    PageId: 1,
  });
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    callApiGet("notifications", {
      page: 0,
      size: 25,
      userId: "",
    })
      .then((res) => {
        setData(res);
      })
      .finally(() => setLoading(false));
  };

  return (
    <ContentLayout>
      <div>
        <MainTable
          limit={params?.PageSize}
          page={params?.PageId}
          total={data?.totalElements || 0}
          columns={COLUMN_TABLE}
          loading={loading}
          title="Danh sách thông báo"
          endpoint="model"
          listBtn={[
            {
              type: "add",
              onClick: () => {
                navigate(`/Notification/add`);
              },
            },
          ]}
          data={data?.content || []}
          linkToDetail={{
            link: "/Notification",
          }}
          onClickUpdateItem={(e) => {
            navigate(`/Notification/${e.id}`);
          }}
        />
      </div>
    </ContentLayout>
  );
};
