/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import MenuAntd from "./MenuAntd";

export const Menu = ({ callback, showMore }) => {
  return (
    <div
      style={{ height: "100%", justifyContent: "space-between" }}
      className="ct-flex-col"
    >
      <div style={{ marginTop: 4, width: "100%" }} className="ct-flex-col">
        <div style={{ marginBottom: 10 }}>
          {/* <IconLogoMenu /> */}
          {/* <Image src={logo} width={170} preview={false} /> */}
        </div>
        <MenuAntd />
      </div>
    </div>
  );
};
