export const LabelInput = ({ label, required, style, className }) => {
  if (label) {
    return (
      <div
        style={{
          fontSize: 12,
          fontWeight: 500,
          color: "#707072",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          ...style,
        }}
        className={className}
      >
        {label} {required ? <span style={{ color: "red" }}>*</span> : ""}
      </div>
    );
  }
  return null;
};
