export const COLUMN_TABLE = [
  {
    title: "id",
    dataIndex: "id",
    type: "link",
  },
  {
    title: "Tên",
    dataIndex: "name",
  },
  {
    title: "model",
    dataIndex: "model",
  },
  {
    title: "serial",
    dataIndex: "serial",
  },
  {
    title: "Loại thiết bị",
    dataIndex: "type",
  },
];
