/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";
import { Login } from "../features/auth/Login";

import { CommonRoutes } from "./const/constants";
import { Header } from "./Header";
import { Menu } from "./Menu";
import "./styles.css";
import storage from "../utils/storage";
import React from "react";
import { useRoutes } from "react-router-dom";

const AppRoutes = () => {
  const auth = storage.getToken();
  const [isShowMenu, setIsShowMenu] = useState(true);

  const element = useRoutes(CommonRoutes);

  if (!auth) {
    return <Login />;
  }
  return (
    <div className="main">
      <div
        className="main-menu"
        style={{
          width: isShowMenu ? 260 : 56,
        }}
      >
        <Menu
          showMore={isShowMenu}
          callback={() => {
            setIsShowMenu(!isShowMenu);
          }}
        />
      </div>
      <div className="main-header">
        <Header />
        <div className="main-body">{element}</div>
      </div>
    </div>
  );
};

export default AppRoutes;
