export const COLUMN_TABLE = [
  {
    title: "id",
    dataIndex: "id",
    type: "link",
  },
  {
    title: "Tên",
    dataIndex: "name",
    type: "link",
  },
  {
    title: "model",
    dataIndex: "model",
  },
  {
    title: "file",
    dataIndex: "file",
  },
];
