/* eslint-disable no-restricted-globals */
import { MenuArray } from "../routes/const/constants";

export const createRowSearchTable = (array) => {
  const newArray = array.map((item) => `'${item.dataIndex}':'SEARCH_COLUMN'`);
  const aaa = JSON.stringify(newArray);
  const bbb = aaa
    .replace("[", "{")
    .replace("]", "}")
    .replace(/["]+/g, "")
    .replaceAll(/[']+/g, '"');
  return [JSON.parse(bbb)];
};

export function FindItemMenu() {
  const foundItemMenu = MenuArray?.find((o) =>
    o?.children?.find(
      (e) => e.key === `/${convertStringToArray(location.pathname, /[/]+/)[1]}`
    )
  );
  const itemMenu = foundItemMenu?.children.find(
    (o) => location?.pathname.search(o.key) !== -1
  );
  return itemMenu;
}

export const convertStringToArray = (str, value) => {
  // /[/]+/
  const converPath = str.split(value || /[/]+/).map((i) => i && i.trim());
  return converPath;
};
export const convertStringToArrayDauCham = (str, value) => {
  // /[/]+/
  const converPath = str
    .split(value || /[.]+/)
    .map((i) => i && i.trim())
    ?.reverse();
  return converPath;
};

export const convertStringDateDate = (str) => {
  const stra = str.split(/[/]+/).map((i) => i && i.trim());
  return `${stra[2]}-${stra[1]}-${stra[0]}T00:00:00`;
};

export function formatBytes(bytes, decimals = 2, isNumber) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  if (isNumber) {
    return {
      value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
      type: sizes[i],
    };
  }
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const uniqueElementsBy = (arr, fn) =>
  arr.reduce((acc, v) => {
    if (!acc.some((x) => fn(v, x))) acc.push(v);
    return acc;
  }, []);

export const uniqueByAttr = (arr, attr) =>
  uniqueElementsBy(
    arr.filter((e) => e[attr] !== null),
    (a, b) => a[attr] === b[attr]
  );

export const deleteItemOfArr = (arr, itemDelete, attr) => {
  return arr.filter((o) => o[attr] !== itemDelete);
};
export const extractContentHTML = (s) => {
  const span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};
export const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
export const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
export const formatMoney = (value, maTienTe) => {
  if (value) {
    // if (maTienTe == 'VND') {
    //   value = value?.toLocaleString('vi-VN');
    // } else {
    //   // value = value?.toFixed(2);
    // }
    value = value?.toLocaleString("en-US");
  }
  return value;
};
export const renderChamCongColor = (value) => {
  switch (value) {
    case 1:
      return "bagde-blue";
    case 2:
      return "bagde-primary";
    case 3:
      return "bagde-success";
    default:
      return "bagde-primary";
  }
};
export const renderColorStatus = (value) => {
  switch (value) {
    case "CXL":
      return "bagde-blue";
    case "DAXL":
      return "bagde-primary";
    case "DXL":
      return "bagde-success";
    case "TC":
      return "bagde-danger";
    case "CD":
      return "bagde-warning";
    case "DD":
      return "bagde-success";
    case "TL":
      return "bagde-danger";
    case "TC_BT":
      return "bagde-danger";
    case "CV_1":
      return "bagde-blue";
    case "CV_2":
      return "bagde-primary";
    case "CV_3":
      return "bagde-success";
    case "DGD":
      return "bagde-warning";
    default:
      return "bagde-primary";
  }
};
export const renderStatus = (value) => {
  switch (value) {
    case "CXL":
      return "Chưa xử lý";
    case "DAXL":
      return "Đang xử lý";
    case "DXL":
      return "Đã xử lý";
    case "TC":
      return "Từ chối";
    case "CD":
      return "Chờ duyệt";
    case "DD":
      return "Đã duyệt";
    case "TL":
      return "Trả lại";
    case "TC_BT":
      return "Từ chối BT";
    case "CV_1":
      return "Chưa chấm công";
    case "CV_2":
      return "Đang chấm công";
    case "CV_3":
      return "Đã chấm công";
    case "DGD":
      return "Dừng giám định";
    default:
      return "";
  }
};
export const TTCongViec = {
  1: {
    label: "Chưa thực hiện",
    color: "bagde-blue",
  },
  2: {
    label: "Đang thực hiện",
    color: "bagde-primary",
  },
  3: {
    label: "Đã thực hiện",
    color: "bagde-success",
  },
};
export const dataLoaiToTrinh = [
  { value: "TTCTT", label: "Tờ trình chuyển tiền trước" },
  { value: "TTPABT", label: "Tờ trình phương án bồi thường" },
  { value: "TTPGD", label: "Tờ trình phí giám định" },
];
