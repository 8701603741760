const IconLeft = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66115 2.06646V1.03119C9.66115 0.941456 9.55803 0.891902 9.48839 0.946813L3.45088 5.66244C3.39959 5.70233 3.35808 5.75341 3.32953 5.81179C3.30098 5.87016 3.28613 5.93429 3.28613 5.99927C3.28613 6.06425 3.30098 6.12838 3.32953 6.18675C3.35808 6.24512 3.39959 6.29621 3.45088 6.3361L9.48839 11.0517C9.55937 11.1066 9.66115 11.0571 9.66115 10.9673V9.93208C9.66115 9.86646 9.63035 9.80351 9.57946 9.76333L4.75803 5.99994L9.57946 2.23521C9.63035 2.19503 9.66115 2.13208 9.66115 2.06646V2.06646Z"
      fill="black"
    />
  </svg>
);
const IconRight = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2193 5.66233L4.1818 0.9467C4.16603 0.93428 4.14707 0.926562 4.1271 0.924431C4.10714 0.9223 4.08697 0.925844 4.06893 0.934655C4.05089 0.943466 4.0357 0.957188 4.0251 0.974243C4.01451 0.991299 4.00894 1.011 4.00903 1.03108V2.06634C4.00903 2.13197 4.03984 2.19491 4.09073 2.23509L8.91216 5.99983L4.09073 9.76456C4.0385 9.80474 4.00903 9.86768 4.00903 9.93331V10.9686C4.00903 11.0583 4.11216 11.1079 4.1818 11.053L10.2193 6.33733C10.2706 6.2973 10.3121 6.2461 10.3407 6.18761C10.3692 6.12913 10.3841 6.06491 10.3841 5.99983C10.3841 5.93475 10.3692 5.87052 10.3407 5.81204C10.3121 5.75356 10.2706 5.70235 10.2193 5.66233Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);
const IconBaCham = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.70435 16.0918C8.70435 15.6133 8.87297 15.2054 9.21021 14.8682C9.552 14.5309 9.95988 14.3623 10.4338 14.3623C10.9124 14.3623 11.3202 14.5309 11.6575 14.8682C11.9993 15.2054 12.1702 15.6133 12.1702 16.0918C12.1702 16.5703 11.9993 16.9805 11.6575 17.3223C11.3202 17.6595 10.9124 17.8281 10.4338 17.8281C9.95988 17.8281 9.552 17.6595 9.21021 17.3223C8.87297 16.9805 8.70435 16.5703 8.70435 16.0918ZM15.6125 16.0918C15.6125 15.6133 15.7812 15.2054 16.1184 14.8682C16.4602 14.5309 16.8681 14.3623 17.342 14.3623C17.8206 14.3623 18.2284 14.5309 18.5657 14.8682C18.9075 15.2054 19.0784 15.6133 19.0784 16.0918C19.0784 16.5703 18.9075 16.9805 18.5657 17.3223C18.2284 17.6595 17.8206 17.8281 17.342 17.8281C16.8681 17.8281 16.4602 17.6595 16.1184 17.3223C15.7812 16.9805 15.6125 16.5703 15.6125 16.0918ZM22.5208 16.0918C22.5208 15.6133 22.6894 15.2054 23.0266 14.8682C23.3684 14.5309 23.7763 14.3623 24.2502 14.3623C24.7288 14.3623 25.1366 14.5309 25.4739 14.8682C25.8157 15.2054 25.9866 15.6133 25.9866 16.0918C25.9866 16.5703 25.8157 16.9805 25.4739 17.3223C25.1366 17.6595 24.7288 17.8281 24.2502 17.8281C23.7763 17.8281 23.3684 17.6595 23.0266 17.3223C22.6894 16.9805 22.5208 16.5703 22.5208 16.0918Z"
      fill="black"
      fillOpacity="0.25"
    />
  </svg>
);
const IconMove = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 14L16 14"
      stroke="#616161"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 10L16 10"
      stroke="#616161"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 6L16 6"
      stroke="#616161"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const IconCheckBox = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="0.5"
      width="15"
      height="15"
      rx="3.5"
      fill="#5DB075"
      stroke="#4B9460"
    />
    <path
      d="M4.5 8.375L7.16667 11L11.7 4.875"
      stroke="white"
      strokeLinecap="round"
    />
  </svg>
);
const IconSync = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8843 2.71831C11.637 2.96563 11.458 3.41614 11.458 4.29199V7.37533C11.458 8.25118 11.637 8.70169 11.8843 8.94901C12.1316 9.19633 12.5822 9.37533 13.458 9.37533H15.708C16.5839 9.37533 17.0344 9.19633 17.2817 8.94901C17.529 8.70169 17.708 8.25118 17.708 7.37533V4.29199C17.708 3.41614 17.529 2.96563 17.2817 2.71831C17.0344 2.47099 16.5839 2.29199 15.708 2.29199H13.458C12.5822 2.29199 12.1316 2.47099 11.8843 2.71831ZM11.0004 1.83443C11.5969 1.23799 12.4589 1.04199 13.458 1.04199H15.708C16.7072 1.04199 17.5691 1.23799 18.1656 1.83443C18.762 2.43086 18.958 3.29285 18.958 4.29199V7.37533C18.958 8.37447 18.762 9.23646 18.1656 9.83289C17.5691 10.4293 16.7072 10.6253 15.708 10.6253H13.458C12.4589 10.6253 11.5969 10.4293 11.0004 9.83289C10.404 9.23646 10.208 8.37447 10.208 7.37533V4.29199C10.208 3.29285 10.404 2.43086 11.0004 1.83443Z"
      fill="#616161"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.71782 11.0513C2.4705 11.2986 2.2915 11.7491 2.2915 12.625V15.7083C2.2915 16.5842 2.4705 17.0347 2.71782 17.282C2.96514 17.5293 3.41565 17.7083 4.2915 17.7083H6.5415C7.41736 17.7083 7.86787 17.5293 8.11519 17.282C8.3625 17.0347 8.5415 16.5842 8.5415 15.7083V12.625C8.5415 11.7491 8.3625 11.2986 8.11519 11.0513C7.86787 10.804 7.41736 10.625 6.5415 10.625H4.2915C3.41565 10.625 2.96514 10.804 2.71782 11.0513ZM1.83394 10.1674C2.43037 9.571 3.29236 9.375 4.2915 9.375H6.5415C7.54065 9.375 8.40264 9.571 8.99907 10.1674C9.5955 10.7639 9.7915 11.6259 9.7915 12.625V15.7083C9.7915 16.7075 9.5955 17.5695 8.99907 18.1659C8.40264 18.7623 7.54065 18.9583 6.5415 18.9583H4.2915C3.29236 18.9583 2.43037 18.7623 1.83394 18.1659C1.2375 17.5695 1.0415 16.7075 1.0415 15.7083V12.625C1.0415 11.6259 1.2375 10.7639 1.83394 10.1674Z"
      fill="#616161"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3333 11.875C18.6785 11.875 18.9583 12.1548 18.9583 12.5C18.9583 16.0702 16.0702 18.9583 12.5 18.9583C12.2748 18.9583 12.0671 18.8372 11.9561 18.6413C11.8452 18.4453 11.8482 18.2049 11.9641 18.0118L12.8391 16.5534C13.0167 16.2575 13.4006 16.1615 13.6966 16.3391C13.9925 16.5167 14.0885 16.9006 13.9109 17.1966L13.6848 17.5734C15.9924 17.038 17.7083 14.9721 17.7083 12.5C17.7083 12.1548 17.9882 11.875 18.3333 11.875Z"
      fill="#616161"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.315 2.42694C4.00742 2.9623 2.2915 5.02818 2.2915 7.50033C2.2915 7.8455 2.01168 8.12533 1.6665 8.12533C1.32133 8.12533 1.0415 7.8455 1.0415 7.50033C1.0415 3.93015 3.92966 1.04199 7.49984 1.04199C7.72501 1.04199 7.93277 1.16311 8.04371 1.35906C8.15465 1.555 8.15162 1.79547 8.03577 1.98855L7.16077 3.44689C6.98318 3.74287 6.59927 3.83885 6.30328 3.66126C6.00729 3.48367 5.91131 3.09975 6.0889 2.80377L6.315 2.42694Z"
      fill="#616161"
    />
  </svg>
);
const IconCheckRadio = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      fill="#007AFF"
    />
    <path
      d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
      fill="#007AFF"
    />
  </svg>
);
const IconRadio = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      fill="#3C3C43"
      fillOpacity="0.6"
    />
  </svg>
);
const IconUnCheckBox = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="3.5"
      fill="white"
      stroke="#616161"
    />
  </svg>
);

const IconVanTay = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.48">
      <path
        d="M5.05901 14.5587V9.72113C5.05901 8.1489 5.78465 6.57668 6.99406 5.36728C7.23594 5.12539 7.23594 4.76257 6.99406 4.52069C6.75218 4.27881 6.26842 4.27881 6.02654 4.39975C4.69619 5.85104 3.84961 7.78608 3.84961 9.72113V14.5587C3.84961 16.6147 4.33337 19.3964 6.75218 21.0895C6.87312 21.2105 6.99406 21.2105 7.115 21.2105C7.35688 21.2105 7.47782 21.0895 7.59876 20.9686C7.84064 20.7267 7.7197 20.3639 7.47782 20.122C5.42183 18.6707 5.05901 16.3728 5.05901 14.5587Z"
        fill="black"
      />
      <path
        d="M8.80813 3.79442C9.8966 3.31066 10.8641 3.06878 11.7107 3.06878C13.7667 3.06878 15.5808 3.91536 16.6693 5.2457C16.7902 5.36664 17.0321 5.48758 17.153 5.48758C17.274 5.48758 17.3949 5.48758 17.5158 5.36664C17.7577 5.12476 17.8787 4.76194 17.6368 4.52006C16.3064 2.8269 14.1295 1.85938 11.7107 1.85938C10.6222 1.85938 9.53377 2.2222 8.32437 2.8269C7.96155 2.94784 7.84061 3.31066 8.08249 3.55254C8.20343 3.91536 8.56625 4.0363 8.80813 3.79442Z"
        fill="black"
      />
      <path
        d="M19.209 6.09177C19.0881 5.72895 18.7253 5.60801 18.4834 5.72895C18.1205 5.84989 17.9996 6.21271 18.1205 6.45459C18.2415 7.18023 18.3624 7.90587 18.3624 8.51057V12.1388C18.3624 12.5016 18.6043 12.7435 18.9671 12.7435C19.33 12.7435 19.5718 12.5016 19.5718 12.1388V8.51057C19.5718 7.66399 19.4509 6.93835 19.209 6.09177Z"
        fill="black"
      />
      <path
        d="M18.9671 13.9531C18.6042 13.9531 18.3624 14.195 18.3624 14.5578C18.3624 16.6138 17.7577 21.2095 11.7106 21.2095C10.8641 21.2095 10.1384 21.0886 9.41278 20.9677C9.04996 20.9677 8.80807 21.0886 8.68713 21.4514C8.68713 21.8142 8.80807 22.0561 9.17089 22.1771C9.89654 22.298 10.8641 22.4189 11.7106 22.4189C18.9671 22.4189 19.5718 16.3719 19.5718 14.5578C19.5718 14.195 19.3299 13.9531 18.9671 13.9531Z"
        fill="black"
      />
      <path
        d="M13.8876 7.54273C12.6782 6.33333 10.8641 6.33333 9.65473 7.54273C9.05003 8.14743 8.68721 8.99402 8.68721 9.71966V15.7667C8.68721 17.5808 9.65473 18.9111 11.4688 19.8786C11.5898 19.8786 11.7107 19.9996 11.7107 19.9996C11.9526 19.9996 12.1945 19.8786 12.1945 19.6368C12.3154 19.3949 12.1945 19.0321 11.9526 18.7902C10.6223 18.0645 9.89661 17.097 9.89661 15.7667V9.71966C9.89661 9.2359 10.1385 8.75214 10.5013 8.51026C11.227 7.78461 12.3154 7.78461 13.0411 8.51026C13.4039 8.87308 13.6458 9.35684 13.6458 9.71966V14.5573C13.6458 15.7667 14.2505 16.3714 15.4599 16.3714C16.6693 16.3714 17.274 15.7667 17.274 14.5573V9.71966C17.274 8.26837 16.6693 6.93803 15.7017 5.97051C14.6133 4.88205 13.2829 4.27734 11.7107 4.27734C10.2594 4.27734 8.92909 4.88205 7.96157 5.84957C6.8731 6.81709 6.2684 8.14743 6.2684 9.71966V15.6457C6.2684 16.4923 6.51028 17.3389 6.8731 18.4274C6.99404 18.6692 7.23592 18.7902 7.47781 18.7902C7.59875 18.7902 7.59875 18.7902 7.71969 18.7902C8.08251 18.6692 8.20345 18.3064 8.08251 17.9436C7.71969 17.097 7.47781 16.3714 7.47781 15.6457V9.71966C7.47781 8.51026 7.96157 7.54273 8.80815 6.81709C9.53379 5.97051 10.6223 5.48675 11.7107 5.48675C12.9201 5.48675 13.8876 5.97051 14.6133 6.81709C15.4599 7.54273 15.9436 8.6312 15.9436 9.71966V14.5573C15.9436 15.041 15.8227 15.162 15.3389 15.162C14.8552 15.162 14.7342 15.041 14.7342 14.5573V9.71966C14.7342 8.99402 14.3714 8.14743 13.8876 7.54273Z"
        fill="black"
      />
      <path
        d="M11.7107 9.11719C11.3479 9.11719 11.106 9.35907 11.106 9.72189V14.5595C11.106 17.3411 12.5573 18.7924 15.3389 18.7924C15.7017 18.7924 15.9436 18.5505 15.9436 18.1877C15.9436 17.8249 15.7017 17.583 15.3389 17.583C13.2829 17.583 12.3154 16.6155 12.3154 14.5595V9.72189C12.3154 9.35907 12.0735 9.11719 11.7107 9.11719Z"
        fill="black"
      />
    </g>
  </svg>
);

const IconKhuonMat = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.48">
      <path
        d="M7.8 10.9852H9V9.78516H7.8V10.9852ZM15 10.9852H16.2V9.78516H15V10.9852ZM15.12 14.2252C14.7567 14.7095 14.2857 15.1027 13.7441 15.3734C13.2026 15.6442 12.6055 15.7852 12 15.7852C11.3945 15.7852 10.7974 15.6442 10.2559 15.3734C9.71433 15.1027 9.24327 14.7095 8.88 14.2252L7.92 14.9452C9.96 17.6656 14.04 17.6656 16.08 14.9452L15.12 14.2252ZM4.2 9.78516V6.78516H3V9.78516H4.2ZM6 4.98516H9V3.78516H6V4.98516ZM4.2 6.78516C4.2 6.30777 4.38964 5.84993 4.72721 5.51236C5.06477 5.1748 5.52261 4.98516 6 4.98516V3.78516C5.20435 3.78516 4.44129 4.10123 3.87868 4.66384C3.31607 5.22645 3 5.98951 3 6.78516H4.2ZM3 15.7852V18.7852H4.2V15.7852H3ZM6 21.7852H9V20.5852H6V21.7852ZM3 18.7852C3 19.5808 3.31607 20.3439 3.87868 20.9065C4.44129 21.4691 5.20435 21.7852 6 21.7852V20.5852C5.52261 20.5852 5.06477 20.3955 4.72721 20.0579C4.38964 19.7204 4.2 19.2625 4.2 18.7852H3ZM15 4.98516H18V3.78516H15V4.98516ZM19.8 6.78516V9.78516H21V6.78516H19.8ZM18 4.98516C18.4774 4.98516 18.9352 5.1748 19.2728 5.51236C19.6104 5.84993 19.8 6.30777 19.8 6.78516H21C21 5.98951 20.6839 5.22645 20.1213 4.66384C19.5587 4.10123 18.7957 3.78516 18 3.78516V4.98516ZM15 21.7852H18V20.5852H15V21.7852ZM21 18.7852V15.7852H19.8V18.7852H21ZM18 21.7852C18.7957 21.7852 19.5587 21.4691 20.1213 20.9065C20.6839 20.3439 21 19.5808 21 18.7852H19.8C19.8 19.2625 19.6104 19.7204 19.2728 20.0579C18.9352 20.3955 18.4774 20.5852 18 20.5852V21.7852Z"
        fill="black"
      />
    </g>
  </svg>
);

const IconThe = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.48">
      <path
        d="M15.0187 5.57944H14.5187V6.07944V8.56383H14.5125V8.06903V5.08464C14.5125 4.25288 13.8335 3.58984 13.0062 3.58984H10.9938C10.1665 3.58984 9.48754 4.25288 9.48754 5.08464V8.06903V8.56383H9.48131V6.07944V5.57944H8.98131H4.45327C3.34925 5.57944 2.44392 6.4663 2.44392 7.57163V18.5144C2.44392 19.6197 3.34925 20.5066 4.45327 20.5066H19.5467C20.6508 20.5066 21.5561 19.6197 21.5561 18.5144V7.57163C21.5561 6.4663 20.6508 5.57944 19.5467 5.57944H15.0187ZM9.06621 17.517C9.06575 17.517 9.06524 17.517 9.06468 17.5169C9.27141 16.9441 9.82924 16.5274 10.4907 16.5274H10.6535C11.0711 16.6861 11.5242 16.7761 12 16.7761C12.4748 16.7761 12.9306 16.6865 13.3471 16.5274H13.5094C14.1675 16.5274 14.7255 16.9409 14.9352 17.5169C14.9347 17.517 14.9342 17.517 14.9338 17.517H9.06621ZM12 11.5534C12.8393 11.5534 13.5125 12.2271 13.5125 13.043C13.5125 13.8589 12.8393 14.5326 12 14.5326C11.1607 14.5326 10.4875 13.8589 10.4875 13.043C10.4875 12.2271 11.1607 11.5534 12 11.5534Z"
        stroke="black"
      />
    </g>
  </svg>
);

const IconQR = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.48">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 2.75C4.42421 2.75 2.75 4.42421 2.75 6.5V9C2.75 9.41421 2.41421 9.75 2 9.75C1.58579 9.75 1.25 9.41421 1.25 9V6.5C1.25 3.59579 3.59579 1.25 6.5 1.25H9C9.41421 1.25 9.75 1.58579 9.75 2C9.75 2.41421 9.41421 2.75 9 2.75H6.5Z"
        fill="#2F3748"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 2C14.25 1.58579 14.5858 1.25 15 1.25H17.5C20.4042 1.25 22.75 3.59579 22.75 6.5V9C22.75 9.41421 22.4142 9.75 22 9.75C21.5858 9.75 21.25 9.41421 21.25 9V6.5C21.25 4.42421 19.5758 2.75 17.5 2.75H15C14.5858 2.75 14.25 2.41421 14.25 2Z"
        fill="#2F3748"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 15.25C22.4142 15.25 22.75 15.5858 22.75 16V17.5C22.75 20.4042 20.4042 22.75 17.5 22.75H16C15.5858 22.75 15.25 22.4142 15.25 22C15.25 21.5858 15.5858 21.25 16 21.25H17.5C19.5758 21.25 21.25 19.5758 21.25 17.5V16C21.25 15.5858 21.5858 15.25 22 15.25Z"
        fill="#2F3748"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 14.25C2.41421 14.25 2.75 14.5858 2.75 15V17.5C2.75 19.5758 4.42421 21.25 6.5 21.25H9C9.41421 21.25 9.75 21.5858 9.75 22C9.75 22.4142 9.41421 22.75 9 22.75H6.5C3.59579 22.75 1.25 20.4042 1.25 17.5V15C1.25 14.5858 1.58579 14.25 2 14.25Z"
        fill="#2F3748"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40533 6.40533C6.34559 6.46507 6.25 6.61561 6.25 7V9C6.25 9.38439 6.34559 9.53493 6.40533 9.59467C6.46507 9.65441 6.61561 9.75 7 9.75H9C9.38439 9.75 9.53493 9.65441 9.59467 9.59467C9.65441 9.53493 9.75 9.38439 9.75 9V7C9.75 6.61561 9.65441 6.46507 9.59467 6.40533C9.53493 6.34559 9.38439 6.25 9 6.25H7C6.61561 6.25 6.46507 6.34559 6.40533 6.40533ZM5.34467 5.34467C5.78493 4.90441 6.38439 4.75 7 4.75H9C9.61561 4.75 10.2151 4.90441 10.6553 5.34467C11.0956 5.78493 11.25 6.38439 11.25 7V9C11.25 9.61561 11.0956 10.2151 10.6553 10.6553C10.2151 11.0956 9.61561 11.25 9 11.25H7C6.38439 11.25 5.78493 11.0956 5.34467 10.6553C4.90441 10.2151 4.75 9.61561 4.75 9V7C4.75 6.38439 4.90441 5.78493 5.34467 5.34467Z"
        fill="#2F3748"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4053 6.40533C14.3456 6.46507 14.25 6.61561 14.25 7V9C14.25 9.38439 14.3456 9.53493 14.4053 9.59467C14.4651 9.65441 14.6156 9.75 15 9.75H17C17.3844 9.75 17.5349 9.65441 17.5947 9.59467C17.6544 9.53493 17.75 9.38439 17.75 9V7C17.75 6.61561 17.6544 6.46507 17.5947 6.40533C17.5349 6.34559 17.3844 6.25 17 6.25H15C14.6156 6.25 14.4651 6.34559 14.4053 6.40533ZM13.3447 5.34467C13.7849 4.90441 14.3844 4.75 15 4.75H17C17.6156 4.75 18.2151 4.90441 18.6553 5.34467C19.0956 5.78493 19.25 6.38439 19.25 7V9C19.25 9.61561 19.0956 10.2151 18.6553 10.6553C18.2151 11.0956 17.6156 11.25 17 11.25H15C14.3844 11.25 13.7849 11.0956 13.3447 10.6553C12.9044 10.2151 12.75 9.61561 12.75 9V7C12.75 6.38439 12.9044 5.78493 13.3447 5.34467Z"
        fill="#2F3748"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40533 14.4053C6.34559 14.4651 6.25 14.6156 6.25 15V17C6.25 17.3844 6.34559 17.5349 6.40533 17.5947C6.46507 17.6544 6.61561 17.75 7 17.75H9C9.38439 17.75 9.53493 17.6544 9.59467 17.5947C9.65441 17.5349 9.75 17.3844 9.75 17V15C9.75 14.6156 9.65441 14.4651 9.59467 14.4053C9.53493 14.3456 9.38439 14.25 9 14.25H7C6.61561 14.25 6.46507 14.3456 6.40533 14.4053ZM5.34467 13.3447C5.78493 12.9044 6.38439 12.75 7 12.75H9C9.61561 12.75 10.2151 12.9044 10.6553 13.3447C11.0956 13.7849 11.25 14.3844 11.25 15V17C11.25 17.6156 11.0956 18.2151 10.6553 18.6553C10.2151 19.0956 9.61561 19.25 9 19.25H7C6.38439 19.25 5.78493 19.0956 5.34467 18.6553C4.90441 18.2151 4.75 17.6156 4.75 17V15C4.75 14.3844 4.90441 13.7849 5.34467 13.3447Z"
        fill="#2F3748"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4053 14.4053C14.3456 14.4651 14.25 14.6156 14.25 15V17C14.25 17.3844 14.3456 17.5349 14.4053 17.5947C14.4651 17.6544 14.6156 17.75 15 17.75H17C17.3844 17.75 17.5349 17.6544 17.5947 17.5947C17.6544 17.5349 17.75 17.3844 17.75 17V15C17.75 14.6156 17.6544 14.4651 17.5947 14.4053C17.5349 14.3456 17.3844 14.25 17 14.25H15C14.6156 14.25 14.4651 14.3456 14.4053 14.4053ZM13.3447 13.3447C13.7849 12.9044 14.3844 12.75 15 12.75H17C17.6156 12.75 18.2151 12.9044 18.6553 13.3447C19.0956 13.7849 19.25 14.3844 19.25 15V17C19.25 17.6156 19.0956 18.2151 18.6553 18.6553C18.2151 19.0956 17.6156 19.25 17 19.25H15C14.3844 19.25 13.7849 19.0956 13.3447 18.6553C12.9044 18.2151 12.75 17.6156 12.75 17V15C12.75 14.3844 12.9044 13.7849 13.3447 13.3447Z"
        fill="#2F3748"
      />
    </g>
  </svg>
);

const IconSyncTable = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3076 12.6166L17.4614 12.0781L17.9999 14.2319"
      stroke="#25282B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4611 12.0781C16.9828 13.4335 16.114 14.617 14.9641 15.4794C13.8143 16.3418 12.4349 16.8445 10.9997 16.9242C9.67313 16.9244 8.37859 16.5163 7.29196 15.7553C6.20533 14.9943 5.37929 13.9172 4.92603 12.6704"
      stroke="#25282B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.69201 8.31066L4.53821 8.84911L3.99976 6.69531"
      stroke="#25282B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.53809 8.84605C5.44268 6.36918 8.22109 4 10.9995 4C12.3326 4.00375 13.6319 4.41973 14.7192 5.19092C15.8066 5.96211 16.6288 7.05075 17.0732 8.3076"
      stroke="#25282B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export {
  IconLeft,
  IconRadio,
  IconRight,
  IconBaCham,
  IconMove,
  IconCheckBox,
  IconSync,
  IconCheckRadio,
  IconUnCheckBox,
  IconVanTay,
  IconKhuonMat,
  IconThe,
  IconQR,
  IconSyncTable,
};
