/* eslint-disable no-restricted-imports */
/* eslint-disable */
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  CodepenOutlined,
  UserOutlined,
  GatewayOutlined,
  PartitionOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { Dashboard } from "../../features/dashboard/Dashboard";
import React from "react";
import { Model } from "../../features/model";
import { Customer } from "../../features/customer";
import { Devices } from "../../features/devices";
import { Firmware } from "../../features/firmware";
import { Notif } from "../../features/notify";
import FormModel from "../../features/model/FormModel";
import FormCustomer from "../../features/customer/FormCustomer";

function getItem(code, label, key, icon, children, subtitle) {
  return {
    code,
    key,
    icon,
    children,
    label,
    subtitle,
  };
}

export const CommonRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "/Dashboard", element: <Dashboard /> },
  { path: "/Model", element: <Model /> },
  { path: "/Model/add", element: <FormModel /> },
  { path: "/Model/:id", element: <FormModel /> },
  { path: "/Customer", element: <Customer /> },
  { path: "/Customer/add", element: <FormCustomer /> },
  { path: "/Customer/:id", element: <FormCustomer /> },
  { path: "/Devices", element: <Devices /> },
  { path: "/Firmware", element: <Firmware /> },
  { path: "/Notification", element: <Notif /> },
];

export const MenuArray = [
  getItem([], "Dashboard", "/Dashboard", <AppstoreAddOutlined />),
  getItem([], "Model", "/Model", <CodepenOutlined />),
  getItem([], "Customer", "/Customer", <UserOutlined />),
  getItem([], "Devices", "/Devices", <GatewayOutlined />),
  getItem([], "Firmware", "/Firmware", <PartitionOutlined />),
  getItem([], "Notification", "/Notification", <BellOutlined />),
];
