export const COLUMN_TABLE = [
  {
    title: "title",
    dataIndex: "title",
    type: "link",
  },
  {
    title: "status",
    dataIndex: "status",
  },
  {
    title: "body",
    dataIndex: "body",
  },
  {
    title: "userId",
    dataIndex: "userId",
  },
  {
    title: "Thời gian tạo",
    dataIndex: "createdAt",
    type: "datetime",
  },
];
