/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_mobile_analytics_app_id": "92479d372b3f4446924cf7747adc443e",
    "aws_mobile_analytics_app_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:88d727a2-6628-4aaa-8574-7bd18f64eb8e",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_aIR8WQyv0",
    "aws_user_pools_web_client_id": "52v9trcd9opf8jsg558kgu9fpf",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "92479d372b3f4446924cf7747adc443e",
            "region": "ap-southeast-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "92479d372b3f4446924cf7747adc443e",
                "region": "ap-southeast-1"
            }
        }
    },
    "oauth": {
        "domain": "autotech-prod.auth.ap-southeast-1.amazoncognito.com",
        "scope": ['openid'],
        "responseType": 'code',
        "redirectSignIn": 'w2g://',
    },
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
    ],
    "aws_cognito_social_providers": [ 'Facebook'],
    "aws_cognito_signup_attributes": [
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "awsmobile": {
        "aws_project_region": "ap-southeast-1",
        "aws_cognito_identity_pool_id": "ap-southeast-1:88d727a2-6628-4aaa-8574-7bd18f64eb8e",
        "aws_cognito_region": "ap-southeast-1",
        "aws_user_pools_id": "ap-southeast-1_aIR8WQyv0",
        "aws_user_pools_web_client_id": "15fi1dj69958je6jl9cd1vuouk",
        "oauth": {
            "domain": "autotech-prod.auth.ap-southeast-1.amazoncognito.com"
        },
        "aws_cognito_username_attributes": [
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
        ],
        "aws_cognito_mfa_configuration": "OPTIONAL",
        "aws_cognito_mfa_types": [
            "SMS",
            "TOTP"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": [
                "REQUIRES_LOWERCASE",
                "REQUIRES_UPPERCASE",
                "REQUIRES_NUMBERS",
                "REQUIRES_SYMBOLS"
            ]
        },
        "aws_cognito_verification_mechanisms": [
        ],
        "aws_mobile_analytics_app_id": "92479d372b3f4446924cf7747adc443e",
        "aws_mobile_analytics_app_region": "ap-southeast-1"
    }
};


export default awsmobile;
