/* eslint-disable */
import { Form, Input } from "antd";

import { LabelInput } from "../other/LabelInput";

const TextInput = ({
  label,
  name,
  required,
  rules = [],
  isFocus,
  ...props
}) => {
  return (
    <div>
      <LabelInput label={label} required={required} />
      <Form.Item
        name={name || ""}
        key={name}
        shouldUpdate={true}
        rules={[
          {
            required: !!required,
            message: "Bắt buộc nhập",
          },
          ...rules,
        ]}
      >
        <Input
          {...props}
          placeholder={`Nhập ${label?.replaceAll("*", "")?.toLowerCase()}...`}
          className="custom-text-input"
          autoFocus={isFocus}
        />
      </Form.Item>
    </div>
  );
};

export default TextInput;
