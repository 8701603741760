// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.main-menu {
    height: 100%;
    overflow: auto;
    background-color: #FFF;
    overflow-x: hidden;
}

.main-header {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: 100%;
    overflow: hidden;
}

.main-body {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #F5F5F5;
}
`, "",{"version":3,"sources":["webpack://./src/routes/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAO;IACP,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".main {\r\n    width: 100%;\r\n    height: 100vh;\r\n    display: flex;\r\n    flex-direction: row;\r\n}\r\n\r\n.main-menu {\r\n    height: 100%;\r\n    overflow: auto;\r\n    background-color: #FFF;\r\n    overflow-x: hidden;\r\n}\r\n\r\n.main-header {\r\n    display: flex;\r\n    flex-direction: column;\r\n    flex: 1;\r\n    height: 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n.main-body {\r\n    width: 100%;\r\n    height: 100%;\r\n    overflow: auto;\r\n    background-color: #F5F5F5;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
