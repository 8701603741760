/* eslint-disable */

import { Button, Form } from "antd";
import React, { useState } from "react";
import TextInput from "../../components/custom/form/TextInput";
import { BtnLoading } from "../../components/custom/button/BtnLoading";
import { gui } from "../../utils/gui";
import { Auth } from "aws-amplify";
import storage from "../../utils/storage";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (value) => {
    setLoading(true);
    await Auth.signIn(value.email, value.password)
      .then(async (user) => {
        console.log(user);
        storage.setToken(user.signInUserSession?.accessToken?.jwtToken);
        setTimeout(() => {
          navigate(0);
        }, 300);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div style={styles.main} className="ct-flex-row">
      <Form name="login" initialValues={{ remember: true }} onFinish={onSubmit}>
        <div className="form-login ct-flex-col">
          <div style={{ fontSize: 24, fontWeight: 700, color: gui.mainColor }}>
            Đăng nhập
          </div>
          <div style={{ width: 480, marginTop: 30 }}>
            <TextInput label={"Tài khoản"} name="email" type="email" required />
            <TextInput label={"Mật khẩu"} name="password" required />
            <div
              className="ct-flex-row"
              style={{
                justifyContent: "space-between",
                marginTop: 8,
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              <div />
              <div>
                <Button
                  type="link"
                  style={{
                    border: "unset",
                    background: "unset",
                    color: gui.mainColor,
                  }}
                  // onClick={handleForgetPassword}
                >
                  {/* {"Quên mật khẩu"} */}
                </Button>
              </div>
            </div>
          </div>

          <BtnLoading
            loading={loading}
            style={{
              ...styles.btnLogin,
              width: loading ? "auto" : styles.btnLogin?.width || 200,
            }}
            type="submit"
            label={"Đăng nhập"}
          />
        </div>
      </Form>
    </div>
  );
};

const styles = {
  main: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#C4FFB29C",
    justifyContent: "center",
    // position: 'relative',
  },
  btnLogin: {
    fontWeight: 500,
    fontSize: 20,
    height: 44,
    backgroundColor: gui.mainColor,
    borderRadius: 4,
    color: "#FFF",
    padding: "0 12px 0 12px",
    width: "auto",
  },
};
