/* eslint-disable */
import useUrlState from "@ahooksjs/use-url-state";
import { Input } from "antd";
import { IconSearch } from "../icons/headerIcon";
import { useCallback, useState, useEffect } from "react";
import _debounce from "lodash/debounce";

const TextInputSearch = ({ name, onChange, placeholderSearch }) => {
  const [params, setParams] = useUrlState({});
  const [value, setValue] = useState(params[name] || "");

  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  useEffect(() => {
    if (!params?.keyword) {
      setValue("");
    }
  }, [params.keyword]);

  function handleDebounceFn(inputValue) {
    if (onChange) {
      onChange(inputValue);
    } else {
      setParams({
        PageId: 1,
        [name]: inputValue,
      });
    }
  }

  function handleChange(event) {
    setValue(event.target.value);
    debounceFn(event.target.value);
  }

  return (
    <div>
      <Input
        name={name}
        onChange={handleChange}
        id={name}
        value={value}
        allowClear
        suffix={<IconSearch />}
        placeholder={placeholderSearch || "Tìm kiếm"}
        // size="small"
      />
    </div>
  );
};

export default TextInputSearch;
