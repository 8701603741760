import { Button } from 'antd';
import React from 'react';

export const BtnLoading = ({
  icon,
  label,
  loading,
  onClick,
  style,
  type,
  disabled,
  isUpload,
  onBlur,
  widthOk,
}) => {
  return (
    <Button
      style={{
        height: 32,
        borderRadius: 4,
        backgroundColor: '#45AF0F',
        fontSize: 14,
        fontWeight: 500,
        width: `${widthOk || 'auto'}`,
        ...style,
      }}
      type="primary"
      hidden={isUpload}
      icon={icon ? icon : null}
      loading={loading || false}
      disabled={loading || disabled || false}
      onClick={onClick}
      htmlType={type || ''}
      onBlur={onBlur}
    >
      {label}
      {/* {label.toUpperCase()} */}
    </Button>
  );
};
