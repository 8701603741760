/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { useState } from "react";
import { Col, Form, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { HeaderDetailPage } from "../../components/custom/header/HeaderDetailPage";
import { ContentLayout } from "../../components/Layout";
import TextInput from "../../components/custom/form/TextInput";
import { callApiGet, callApiPost } from "../../lib/axios";

export default function FormModel() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchData = () => {
    setLoading(true);
    callApiGet(`model/${id}`)
      .then((res) => {
        form.setFieldsValue({
          name: res.name,
          description: res.description,
        });
      })
      .finally(() => setLoading(false));
  };

  const handleFinish = (values) => {
    const dto = {
      name: values.name,
      description: values.description,
      metadata: "string",
    };
    callApiPost("model", dto).then((res) => {
      console.log("res", res);
    });
  };

  return (
    <ContentLayout loading={loading}>
      <HeaderDetailPage
        backUrl="/Model"
        title={id ? "Chi tiết Model" : "Thêm mới Model"}
        onClickSubmit={() => {
          form.submit();
        }}
      />

      <Form
        id="Model"
        form={form}
        onFinish={handleFinish}
        style={{
          background: "#FFF",
          marginTop: 16,
          borderRadius: 8,
          padding: 16,
        }}
        initialValues={{}}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <TextInput label="Tên" maxLength={250} name="name" required />
          </Col>
          <Col span={24}>
            <TextInput label="Mô tả" name="description" required />
          </Col>
        </Row>
      </Form>
    </ContentLayout>
  );
}
