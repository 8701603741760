/* eslint-disable */

import React from "react";
import { ContentLayout } from "../../components/Layout";

export const Dashboard = () => {
  return (
    <ContentLayout>
      <div>Trang chu</div>
    </ContentLayout>
  );
};
