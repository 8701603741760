/* eslint-disable no-unused-vars */
import axios from "axios";
import Swal from "sweetalert2";
import QueryString from "query-string";

import storage from "../utils/storage";

const urlApi = "https://api.iot.waste2green.vn/thingmanager/api/v1/";

function authRequestInterceptor(config) {
  const token = storage.getToken();
  // console.log("token", token);
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = "application/json";
  config.headers["Content-Type"] = "application/json";
  config.headers["X-Origin"] = "http://localhost:3000";
  // config.headers["Accept-Language"] = "VN";
  // config.headers["Sec-Fetch-Dest"] = "empty";
  // config.headers["Sec-Fetch-Mode"] = "cors";
  // config.headers["Sec-Fetch-Site"] = "cross-site";
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Content-Type"] = "application/json";
  config.headers["Origin"] = "http://localhost:3000";

  config.headers["Access-Control-Allow-Headers"] =
    "Origin, X-Requested-With, Content-Type, Accept, Authorization";
  config.headers["Access-Control-Allow-Methods"] =
    "GET, POST, PUT, DELETE, OPTIONS, HEAD";
  config.headers["Allow"] = "GET, POST, PUT, DELETE, OPTIONS, HEAD";
  config.headers["X-Powered-By"] = "";

  return config;
}
axios.interceptors.request.use(authRequestInterceptor);
const token = storage.getToken();

export const showError = (err) => {
  const message = getErrorMessage(err);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    showCloseButton: true,
    // timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    html:
      '<div style="margin-left: 16px">' +
      `<div style="font-weight: bold; font-size:14px">Thông báo lỗi</div>` +
      `<div style="font-size:12px; margin-top: 6px">${message}</div>` +
      "</div>",
  });
  return Toast.fire({
    icon: "error",
  });
};

export function callApi(url, method, data = {}) {
  return axios({
    method,
    url: `${urlApi}${url}`,
    data,
  });
}

export function callApiGet(url, params = {}) {
  console.log("token", token);
  const dto = QueryString.stringify(params);
  return fetch(`${urlApi}${url}${dto ? "?" : ""}${dto}`, {
    method: "GET",
    cache: "no-cache",
    headers: {
      // "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then((responseJson) => {
      return responseJson.json();
    })
    .catch((error) => console.log("error", error));
}

export function callApiPost(url, body = {}) {
  return fetch(`${urlApi}${url}`, {
    method: "POST",
    body: JSON.stringify(),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => console.log("error", error));
}

axios.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  async (error) => {
    const { config, response } = error;
    // if (response?.status === 401) {
    //   const response = await axios.post(`${API_URL}/Token/refresh`, {
    //     accessToken: storage.getToken(),
    //     refreshToken: storage.getRefreshToken(),
    //     provider: "Web",
    //   });
    //   // eslint-disable-next-line no-debugger
    //   if (response?.successed) {
    //     storage.setToken(response.accessToken);
    //     storage.setRefreshToken(response.refreshToken);
    //     config.headers["Authorization"] = `Bearer ${response.accessToken}`;
    //     return config;
    //   } else {
    //     storage.clearToken();
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 500);
    //   }
    // }
    showError(error);
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(async (req) => {
  return req;
});

export function getErrorMessage(err) {
  if (err.response) {
    switch (err.response.status) {
      case 400:
        if (
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length > 0
        ) {
          return err.response.data.errors[0].title;
        }
        if (err.response.data) {
          return err.response.data.title;
        }
        return "400";
      case 401:
        // let cookieInfo = JSON.parse(document.cookie);
        // let userAccount = cookieInfo.userAccount;
        // callApi(ACCOUNT_API.login, 'POST', { ...userAccount, provider: 'web' }).then((res: any) => {
        //   if (res?.token) {
        //     storage.setToken(res?.token);
        //     storage.setRefreshToken(res?.refreshToken);
        //     document.cookie = JSON.stringify({ userAccount: userAccount });
        //   } else {
        //     storage.clearToken();
        //   }
        // });
        storage.clearToken();
        setTimeout(() => {
          window.location.reload();
        }, 500);

        return "Hết phiên đăng nhập";
      // return 'Hết phiên đăng nhập';
      case 403:
        if (
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length > 0
        ) {
          return err.response.data.errors[0].message;
        }
        if (err.response.data && err.response.data.message) {
          return err.response.data.message;
        }
        // eslint-disable-next-line no-debugger
        setTimeout(() => {
          window.location.replace("/403");
        }, 2000);
        return "Bạn không có quyền thực hiện!";
      case 500:
        return "500";
      default:
        if (err.response && err.response.data && err.response.data.message)
          return err.response.data.message;
        return err.message;
    }
  } else {
    if (err.message == "Network Error") {
      return "Network Error";
    }
    return "Other";
  }
}
