/* eslint-disable radix */
import { Button } from "antd";
import React from "react";
import {
  IconAdd,
  IconDelete,
  IconDownload,
  IconEdit,
  IconFilter,
  IconOption,
  IconUpload,
} from "../icons/headerIcon";
import { IconSync } from "../icons/tableIcon";
import TextInputSearch from "./TextInputSearch";

export const HeaderTable = ({
  listBtn = [],
  placeholderSearch,
  title,
  showSearch,
  showSearchSub,
}) => {
  return (
    <div
      className="ct-flex-row"
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        // minWidth: 1000,
        overflow: "auto",
      }}
    >
      <div
        style={{ marginLeft: 8, alignItems: "center" }}
        className="ct-flex-row"
      >
        {title && (
          <span style={{ marginRight: 16, fontWeight: 700 }}>{title}</span>
        )}
        {showSearch && (
          <TextInputSearch
            placeholderSearch={placeholderSearch}
            name="keyword"
          />
        )}
        {showSearchSub && (
          <span style={{ marginLeft: 16, fontSize: 14, fontStyle: "italic" }}>
            {showSearchSub}
          </span>
        )}
      </div>
      <div className="ct-flex-row-center" style={{ padding: 10 }}>
        {listBtn?.map((item, index) => {
          const btnObj = {
            add: { label: "Thêm mới", icon: <IconAdd /> },
            filter: {
              label: "Bộ lọc",
              icon: <IconFilter color={item.color} />,
            },
            upload: { label: "Tải lên", icon: <IconUpload /> },
            download: { label: "Tải xuống", icon: <IconDownload /> },
            option: { label: "Tùy chỉnh", icon: <IconOption /> },
            delete: { label: "Xóa", icon: <IconDelete /> },
            edit: { label: item.label || "Chỉnh sửa", icon: <IconEdit /> },
            sync: { label: "Đồng bộ", icon: <IconSync /> },
          };
          if (item.type) {
            return (
              <Button
                {...item}
                className="ct-flex-row-center"
                key={index.toString()}
                disabled={item.disabled}
                icon={btnObj[item.type]?.icon}
                type="text"
              >
                <div style={{ marginLeft: 6, color: item.color }}>
                  {item.text || btnObj[item.type]?.label}
                </div>
              </Button>
            );
          }
          return (
            <Button
              {...item}
              style={{
                marginLeft: 12,
                borderRadius: 16,
                height: 34,
                backgroundColor: item.backgroundColor || "#FFF",
                boxShadow: "none",
                border: `1px solid ${item.borderColor || "#B7C1D6"}`,
              }}
              className="ct-flex-row-center"
              disabled={item.disabled}
              key={index.toString()}
            >
              <span style={{ marginLeft: 4 }}>{item.text}</span>
            </Button>
          );
        })}
      </div>
    </div>
  );
};
