export const COLUMN_TABLE = [
  {
    title: "Tên model",
    dataIndex: "name",
    type: "link",
  },
  {
    title: "Mô tả",
    dataIndex: "description",
  },
];
