export const statusCustomer = [
  {
    label: "Hoạt động",
    value: true,
  },
  {
    label: "Không hoạt động",
    value: false,
  },
];

export const COLUMN_TABLE = [
  {
    title: "Tên ",
    dataIndex: "name",
    type: "link",
  },
  {
    title: "Email ",
    dataIndex: "email",
  },
  {
    title: "Trạng thái ",
    dataIndex: "enabled",
    type: "filter",
    filterData: statusCustomer,
  },
  {
    title: "Ngày tạo",
    dataIndex: "createDate",
    type: "datetime",
  },
  {
    title: "Sub",
    dataIndex: "sub",
  },
];
