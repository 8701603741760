import { Spin } from "antd";
import * as React from "react";

export const ContentLayout = ({ children, style, loading }) => {
  return (
    <div
      id="parentTable"
      className="content-layout"
      style={{
        ...style,
      }}
    >
      <Spin spinning={loading || false} tip="Loading...">
        {children}
      </Spin>
    </div>
  );
};
