/* eslint-disable radix */
import React from "react";
import { IconArrowLeft } from "../icons/detailPageIcon";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { gui } from "../../../utils/gui";

export const HeaderDetailPage = ({
  onClickSubmit,
  title,
  titleSub,
  backUrl,
  onClickSubmitAndReset,
  backToList,
}) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "#FFF",
        height: 100,
        borderRadius: 8,
        padding: 16,
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="ct-flex-row"
    >
      <div>
        <div>{titleSub}</div>
        <div
          style={{
            fontSize: 20,
            fontWeight: 500,
            marginTop: 8,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(backToList || -1);
          }}
        >
          <IconArrowLeft /> {title}
        </div>
      </div>
      <div>
        <div className="ct-flex-row">
          {backUrl && (
            <Button
              onClick={() => {
                navigate(backUrl);
              }}
              size="small"
              style={{ marginRight: 12, height: 32, borderRadius: 8 }}
            >
              Hủy
            </Button>
          )}

          {onClickSubmit && <BtnSave onClick={onClickSubmit} />}
          {onClickSubmitAndReset && (
            <Button
              onClick={onClickSubmitAndReset}
              size="small"
              style={{
                marginRight: 12,
                backgroundColor: "#1890FF",
                color: "#FFF",
              }}
            >
              Lưu & Thêm mới
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export const BtnSave = ({ ...props }) => (
  <Button
    size="small"
    style={{
      marginRight: 12,
      backgroundColor: gui.mainColor,
      height: 32,
      color: "#FFF",
      borderRadius: 8,
    }}
    {...props}
  >
    Lưu
  </Button>
);
