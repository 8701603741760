/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { CtPopupConfirm } from "../components/custom/popup/CtPopupConfirm";
import storage from "../utils/storage";
import { FindItemMenu } from "../utils/format";

export const Header = ({ userInfo }) => {
  const [isOpenPopupLogout, setIsOpenPopupLogout] = useState(false);
  const navigate = useNavigate();

  const itemMenu = FindItemMenu();
// console
  return (
    <div
      style={{
        height: 60,
        backgroundColor: "#fff",
        width: "100%",
        justifyContent: "space-between",
        color: "#45AF0F",
      }}
      className="ct-flex-row header"
    >
      <div className="ct-flex-row" style={{ marginLeft: 24 }}>
        {itemMenu?.subtitle || itemMenu?.label || ""}
      </div>
      <div style={{ marginRight: 24 }} className="ct-flex-row">
        <div
          style={{
            height: 30,
            width: 1,
            backgroundColor: "#FFF",
            marginLeft: 16,
            marginRight: 16,
          }}
        />
        {/* Ảnh */}
        <div
          onClick={() => {
            navigate("/profile");
          }}
          style={{ marginLeft: 24, cursor: "pointer" }}
        >
          Hello,{` `}
          <span style={{ fontWeight: 500 }}>{userInfo?.hoTen || "Admin"}</span>
        </div>
        <div
          style={{
            height: 30,
            width: 1,
            backgroundColor: "#FFF",
            marginLeft: 24,
            marginRight: 16,
          }}
        />
        <div
          onClick={() => {
            setIsOpenPopupLogout(true);
          }}
          style={{ cursor: "pointer" }}
        >
          Logout
        </div>
      </div>

      <CtPopupConfirm
        title="Đăng xuất"
        isOpen={isOpenPopupLogout}
        onOk={() => {
          storage.clearToken();
          document.cookie = JSON.stringify({ userAccount: {} });
          navigate("/");
        }}
        txtOk="Xác nhận"
        onClose={() => setIsOpenPopupLogout(false)}
      >
        Xác nhận đăng xuất khỏi hệ thống.
      </CtPopupConfirm>
    </div>
  );
};
