/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
import React, { useEffect } from "react";
import { IconBaCham, IconLeft, IconRight } from "../icons/tableIcon";
import useUrlState from "@ahooksjs/use-url-state";
import { Select } from "antd";
import { gui } from "../../../utils/gui";

const createArray = (number, page) => {
  const data = [...new Array(parseInt(number))]
    .map((item, index) => ({
      id: index + 1,
    }))
    .filter(
      (e) =>
        e.id === 1 ||
        e.id === number ||
        e.id === page - 1 ||
        e.id === page - 2 ||
        // e.id === page - 3 ||
        e.id === page + 1 ||
        e.id === page + 2 ||
        // e.id === page + 3 ||
        e.id === page
    );
  return data;
};

const ItemMap = ({ item, page, onClick, index }) => {
  const onClickItem = () => {
    if (item.type !== "show") {
      onClick && onClick(item.type, item.value);
    }
  };

  if (item.type === "show") {
    return <div key={index.toString()}>{item.content()}</div>;
  } else {
    const checkDisabled =
      (page === 1 && (item.type === "first" || item.type === "pre")) ||
      (item.value && page === item.value);
    return (
      <div
        style={{
          marginLeft: 4,
          marginRight: 4,
        }}
        key={index.toString()}
      >
        <div
          className="ct-flex-row"
          onClick={!checkDisabled ? onClickItem : () => {}}
          style={{
            alignItems: "center",
            border: "1px solid #D9D9D9",
            borderRadius: 2,
            color: "black",
            height: 32,
            minWidth: 32,
            backgroundColor: "#FFF",
            justifyContent: "center",
            cursor: !checkDisabled ? "pointer" : "",
            opacity: checkDisabled ? 0.3 : 1,
          }}
          disabled={checkDisabled ? true : false}
        >
          {item.type === "show" ? item.content : item.icon}
        </div>
      </div>
    );
  }
};

export const CtPagination = ({ total, limit, tableType, callback, PageId }) => {
  const pageSize = limit || gui.pageSize;
  const page = PageId ? parseInt(PageId) : 1;

  const sumPage =
    total % pageSize === 0
      ? total / pageSize
      : Math.floor(total / pageSize) + 1;

  const [params, setParams] = useUrlState({
    PageSize: pageSize,
    PageId: 1,
  });
  const limitInt = parseInt(params.PageSize);

  const onChange = (value) => {
    if (tableType === "state") {
      callback(null, {
        PageId: value,
        PageSize: pageSize,
      });
    } else {
      setParams({
        PageId: value,
      });
    }
  };

  useEffect(() => {
    if (sumPage < parseInt(page)) {
      onChange(sumPage);
    }
  }, [sumPage]);

  const onClickPage = (type, value) => {
    switch (type) {
      case "next":
        if (page < total / pageSize) {
          onChange(page + 1);
        }
        return;
      case "pre":
        if (page > 0) {
          onChange(page - 1);
        }
        return;
      default:
        onChange(value);
        break;
    }
  };

  return (
    <div
      className="ct-flex-row"
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 12,
        padding: "0 16px 0 0",
      }}
    >
      <div />
      <div className="ct-flex-row">
        {[
          {
            type: "show",
            content: () => (
              <div
                style={{
                  height: "100%",
                  alignItems: "center",
                  marginRight: 12,
                  fontSize: 14,
                }}
                className="ct-flex-row"
              >
                {page === 1 ? 1 : (page - 1) * limitInt + 1} -{" "}
                {page * limitInt > total ? total : page * limitInt} của {total}{" "}
                bản ghi
              </div>
            ),
          },
          {
            type: "pre",
            content: "Previous",
            icon: <IconLeft />,
          },
          {
            type: "show",
            content: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {createArray(sumPage, page).map((item, index) => {
                  if (
                    item.id === page - 2 &&
                    item.id !== 1 &&
                    item.id !== sumPage
                  ) {
                    return <IconBaCham key={index.toString()} />;
                  }
                  if (
                    item.id === page + 2 &&
                    item.id !== 1 &&
                    item.id !== sumPage
                  ) {
                    return <IconBaCham key={index.toString()} />;
                  }
                  return (
                    <div
                      key={index.toString()}
                      onClick={() => onClickPage("number", item.id)}
                      style={{
                        ...style.viewMain,
                        color: item.id === page ? "#1890FF" : "#333",
                        border:
                          item.id === page
                            ? "1px solid #1890FF"
                            : "1px solid #D9D9D9",
                        borderRadius: 2,
                        height: 32,
                        minWidth: 42,
                        paddingTop: 1,
                      }}
                    >
                      {item.id}
                    </div>
                  );
                })}
              </div>
            ),
          },
          {
            type: "next",
            content: "Next",
            icon: <IconRight />,
            value: sumPage,
          },
          {
            type: "show",
            content: () => (
              <Select
                style={{ width: 120, marginTop: 3, marginLeft: 8 }}
                size={"small"}
                onChange={(value) =>
                  tableType === "state"
                    ? callback(null, {
                        PageId: 1,
                        PageSize: value,
                      })
                    : setParams({ PageSize: value, PageId: 1 })
                }
                defaultValue={limitInt}
                options={[
                  { value: 20, label: "20 / Trang" },
                  { value: 50, label: "50 / Trang" },
                  { value: 100, label: "100 / Trang" },
                ]}
              />
            ),
          },
        ].map((item, index) => {
          return (
            <ItemMap
              item={item}
              index={index}
              page={page}
              key={index.toString()}
              onClick={onClickPage}
            />
          );
        })}
      </div>
    </div>
  );
};

const style = {
  viewMain: {
    width: 30,
    height: 30,
    borderRadius: 15,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: 6,
    marginRight: 6,
    cursor: "pointer",
  },
};
