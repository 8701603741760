import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const ColumnList = ({ data = [], fieldName, link }) => {
  const firstItem = data && data[0];

  if (!data || data.length === 0) {
    return null;
  }

  if (data.length === 1) {
    return (
      <>
        {link ? (
          <Link style={{ color: '#1890FF' }} to={`${link}/${firstItem?.id}`}>
            {firstItem[fieldName || 'name']}
          </Link>
        ) : (
          <div>{firstItem[fieldName || 'name']}</div>
        )}
      </>
    );
  }

  return (
    <div>
      {link ? (
        <Link style={{ color: '#1890FF' }} to={`${link}/${firstItem?.id}`}>
          {firstItem[fieldName || 'name']}
        </Link>
      ) : (
        firstItem[fieldName || 'name']
      )}
      <Tooltip
        placement="bottom"
        title={
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {data.slice(1, data.length)?.map((item, index) => (
              <div key={index.toString()}>
                {item[fieldName || 'name']}
                {index + 2 === data.length ? null : (
                  <span style={{ marginRight: 4 }}>,</span>
                )}
              </div>
            ))}
          </div>
        }
      >
        <span
          style={{
            marginLeft: 6,
            backgroundColor: '#DBEDFF',
            padding: '4px 8px 4px 8px',
            fontSize: 12,
            borderRadius: 8,
          }}
        >
          +{data?.length - 1}
        </span>
      </Tooltip>
    </div>
  );
};

export default ColumnList;
